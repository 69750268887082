/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
//import "https://api.mapbox.com/mapbox-gl-js/v0.52.0/mapbox-gl.css"

import "mapbox-gl/dist/mapbox-gl.css"
